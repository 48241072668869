export class UtilitiesService {
    static PROD_API_URL = 'https://booking-api.kintec.net';
    static DEV_API_URL = 'https://dev-booking-api.kintec.net';

    static inProduction() {
        const currentDomain = window.location.hostname;
        return currentDomain === 'booking.kintec.net' || currentDomain === 'booking-app-s3.kintec.net';
    }

    static getApiUrl() {
        return this.inProduction() ? UtilitiesService.PROD_API_URL : UtilitiesService.DEV_API_URL;

    }

    static appendUTMParamsToHref(href: string) {
        // Parse the current window's UTM parameters
        const urlParams = new URLSearchParams(window.location.search);

        const utmParams: Record<string, string> = {
            utm_medium: urlParams.get('utm_medium') || "",
            utm_source: urlParams.get('utm_source') || "",
            utm_campaign: urlParams.get('utm_campaign') || "",
            utm_term: urlParams.get('utm_term') || "",
            utm_content: urlParams.get('utm_content') || "",
        };

        // Parse the href string into a URL object
        const linkUrl = new URL(href, window.location.origin);

        // Append UTM parameters if they don't already exist
        Object.keys(utmParams).forEach((key) => {
            if (utmParams[key] && !linkUrl.searchParams.has(key)) {
                linkUrl.searchParams.set(key, utmParams[key]);
            }
        });

        // Return the modified href as a string
        return linkUrl.toString();
    }
}