import React, { useEffect, ReactNode } from 'react';

// Define a type for the UTM parameters
type UTMParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

// Helper function to extract UTM parameters from the current window URL
const getUTMParamsFromURL = (): UTMParams => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams: UTMParams = {};

  ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((key) => {
    if (urlParams.has(key)) {
      utmParams[key as keyof UTMParams] = urlParams.get(key) as string;
    }
  });

  return utmParams;
};

// Props for the UTMWrapper component
interface UTMWrapperProps {
  children: ReactNode;
}

// UTMWrapper component
const UTMWrapper: React.FC<UTMWrapperProps> = ({ children }) => {
  useEffect(() => {
    const utmParams = getUTMParamsFromURL();

    const addUTMParamsToLinks = (links: NodeListOf<HTMLAnchorElement>) => {
      links.forEach((link) => {
        if (link.href) {
          const urlObj = new URL(link.href, window.location.origin);
          Object.entries(utmParams).forEach(([key, value]) => {
            if (value) {
              urlObj.searchParams.set(key, value);
            }
          });
          link.href = urlObj.toString();
        }
      });
    };

    const links = document.querySelectorAll<HTMLAnchorElement>('a');
    addUTMParamsToLinks(links);
  }, []);

  return <>{children}</>;
};

export default UTMWrapper;
