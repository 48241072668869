

//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
  Sidebar,
  Menu,
  MenuItem
} from "react-pro-sidebar";

//import icons from react icons
import { Link } from 'react-router-dom';

//import sidebar css from react-pro-sidebar module and our custom css 
import "./Sidebar.scss";

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import UTMLink from "../utm/UTMLink";

const SidebarNavigation = () => {
  
    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)
    const [activeIndex, setActiveIndex] = useState(() => {
        const initialIndex =
             window.location.pathname === '/booking/footwear' ? 1
                : window.location.pathname === '/booking/bracing' ? 2
                    : window.location.pathname === '/booking/compression' ? 3
                        : 0;
        return initialIndex;
    });

  return (
    <>
      <div id="header">
          <Tooltip id="orthotic-assessment-tooltip" style={{ zIndex: 9 }}/>
          <Tooltip id="footwear-tooltip" style={{ zIndex: 9 }}/>
          <Tooltip id="bracing-tooltip" style={{ zIndex: 9 }} />
          <Tooltip id="compression-tooltip" style={{ zIndex: 9 }} />

          {/* collapsed props to change menu size using menucollapse state */}
        <Sidebar collapsed={menuCollapse} width="50">
            <Menu >
              <MenuItem data-tooltip-id="orthotic-assessment-tooltip" data-tooltip-content="Orthotic Assessment" active={activeIndex === 0} icon={<img src={require("../../assets/booking/orthotic-nav-icon.png")} alt="orthotics-icon"/>} component={<UTMLink to="/booking/orthotic-assessment" onClick={() => setActiveIndex(0)}/>}></MenuItem>
              {/*<MenuItem active={activeIndex === 1} icon={<img src={require("../../assets/booking/anywhere-nav-icon.png")} alt="kintec-anywhere-icon"/>} component={<Link to="/booking/kintec-anywhere" onClick={() => setActiveIndex(1)}/>}></MenuItem>*/}
              <MenuItem data-tooltip-id="footwear-tooltip" data-tooltip-content="Footwear" active={activeIndex === 1} icon={<img src={require("../../assets/booking/footwear-nav-icon.png")} alt="kintec-footwear-icon"/>} component={<UTMLink to="/booking/footwear" onClick={() => setActiveIndex(1)}/>}></MenuItem>
              <MenuItem data-tooltip-id="bracing-tooltip" data-tooltip-content="Bracing" active={activeIndex === 2} icon={<img src={require("../../assets/booking/bracing-nav-icon.png")} alt="kintec-bracing-icon"/>} component={<UTMLink to="/booking/bracing" onClick={() => setActiveIndex(2)}/>}></MenuItem>
              <MenuItem data-tooltip-id="compression-tooltip" data-tooltip-content="Compression" active={activeIndex === 3} icon={<img src={require("../../assets/booking/compression-nav-icon.png")} alt="kintec-compression-icon"/>} component={<UTMLink to="/booking/compression" onClick={() => setActiveIndex(3)}/>}></MenuItem>
            </Menu>
        </Sidebar>

      </div>
    </>
  );
};

export default SidebarNavigation;