import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import OrthoticAssessment from './pages/OrthoticAssessment';
import AppLayout from './components/layout/AppLayout';
import OrthoticAdjustment from './pages/OrthoticAdjustment';
import Footwear from './pages/Footwear';
import Bracing from './pages/Bracing';
import Compression from './pages/Compression';
import KintecAnywhere from './pages/KintecAnywhere';
import Confirmation from './pages/Confirmation';
import App from './App';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: '/booking',
    element: <AppLayout />,
    children: [
      {
        path: 'orthotic-adjustment',
        element: <OrthoticAdjustment />
      },
      {
        path: 'kintec-anywhere',
        element: <KintecAnywhere />
      },
      {
        path: 'orthotic-assessment',
        element: <OrthoticAssessment />
      },
      {
        path: 'footwear',
        element: <Footwear />
      },
      {
        path: 'bracing',
        element: <Bracing />
      },
      {
        path: 'compression',
        element: <Compression />
      }
    ]
  },
  {
    path: "/confirmation/:type/:province",
    element: <Confirmation />,
  }
  
]);

root.render(
  <React.StrictMode>
    <App />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
