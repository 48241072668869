import { useState } from "react";
import SlideShow from "../components/slide/SlideShow";
import logo from '../assets/home/kintec-logo-confirmation.png';
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCircle } from "react-icons/fa6";
import UTMWrapper from "../components/utm/UTMWrapper";
const Home = () => {

    const [index, setIndex] = useState(0);
    function handleArrowClick(num: number) {
        setIndex(num);
    }
    const setSlideShow = (step: number) => (e: React.MouseEvent<HTMLAnchorElement> | undefined): void => {
        setIndex(step);

        // different action for mobile
        if (window.innerWidth < 1201) {
            displayMobileOverlay();
        }
    }

    const displayMobileOverlay = () => {
        const homeContainer = document.querySelector('.home-container');
        if (homeContainer) {
            homeContainer.classList.remove('primary');
        }

        const overlay = document.querySelector('.carousel');
        if (overlay) {
            overlay.classList.add('primary');
        }

        const mobileCarouselHeader = document.getElementById('mobile-carousel-header');
        if (mobileCarouselHeader) {
            mobileCarouselHeader.classList.add('active');
        }
    }

    return (
        <UTMWrapper>
            <div id='booking-container'>
                <div className="home-container primary">
                    <a href="https://www.kintec.net/">
                        <img src={logo} className="sidebar-logo" alt="Kintec Logo" />
                    </a>
                    <div className='content'>
                        <h2>How can we help?</h2>
                        <h3>Your Foot Care Journey Starts Here</h3>
                        <p>Choose your appointment type and benefit from Kintec’s industry leading expertise in footwear, orthotics, and sports medicine solutions.</p>
                        <ul>
                            <li><a onClick={setSlideShow(0)} className={index === 0 ? 'active' : ''}>Orthotics <FaCircle /></a></li>
                            {/*<li><a onClick={setSlideShow(1)} className={index === 1 ? 'active' : ''}>Kintec Anywhere <FaCircle /></a></li>*/}
                            <li><a onClick={setSlideShow(1)} className={index === 1 ? 'active' : ''}>Footwear Solutions <FaCircle /></a></li>
                            <li><a onClick={setSlideShow(2)} className={index === 2 ? 'active' : ''}>Bracing <FaCircle /></a></li>
                            <li><a onClick={setSlideShow(3)} className={index === 3 ? 'active' : ''}>Compression <FaCircle /></a></li>
                        </ul>
                        <p className='bold'>Find your nearest Kintec location - <a style={{ color: "white" }} href="https://www.kintec.net/locations/">Click here</a></p>
                    </div>

                    <div className='sidebar-footer'>
                        <p>Any questions? See our <a href='https://www.kintec.net/faq/' target="_blank">FAQ page</a> or call <a href='tel:1-877-441-3540'>1-877-441-3540</a> and speak with a Fitting Expert.</p>
                    </div>
                </div>

                <div id="mobile-carousel-header">
                    <img src={logo} className='sidebar-logo' alt="Kintec Logo" />
                </div>
                <SlideShow selectedIndex={index} arrowClick={handleArrowClick} />
            </div>
        </UTMWrapper>

    );
};

export default Home;
