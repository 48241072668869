import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

const UTMLink: React.FC<LinkProps> = ({ to, ...props }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search).toString();

  const resolvedTo =
    typeof to === 'string'
      ? `${to}${urlParams ? `?${urlParams}` : ''}`
      : {
          ...to,
          search: `${to.search || ''}${urlParams ? `&${urlParams}` : ''}`,
        };

  return <Link to={resolvedTo} {...props} />;
};

export default UTMLink;
