import DetailsPanel from "../components/details-panel/DetailsPanel";
import BookingForm from "../components/form/BookingForm";
import "../styles/styles.scss";
import {FaCheck} from "react-icons/fa";
import { UtilitiesService } from "../services/UtilitiesService";


const OrthoticAssessment = () => {
    const title = "Orthotic Assessment";
    const type = 'Assessment';

    const description = "A great start for people with foot or lower-body pain.  " +
        "Assessments are also a good choice for someone looking for a comprehensive clinical, " +
        "footwear and movement analysis to prevent injury or improve comfort.";

    const details = [
        '45-60 minute appointment',
        'Review of lifestyle, symptoms, and relevant history',
        'Biomechanical examination with gait analysis',
        'Personalized treatment plan including orthotics, footwear, activity modification, and soft-tissue management.',
        'Access to Kiwi follow-up system to ensure your recovery',
        'Select this appointment type to follow-up on a pair of Kintec custom orthotics that are older than 12-months'
    ];

    const acknowledgements = [
        'I acknowledge that all of the information provided above is correct',
        'I acknowledge there is a $95 assessment fee for the pedorthic assessment, and should I proceed with custom orthotics, they cost between $500-$550'
    ];

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>): void => {

        if (e.target.value === 'adjustment') {
            const path = UtilitiesService.appendUTMParamsToHref('/booking/orthotic-adjustment');
            window.location.href = path;
        } 

    }

    return (
    <div className="content-container">
        <DetailsPanel title={title} description={description} details={details} />
        <div className="form-container">
        <div className="info-container choose-orth" onChange={onChangeValue}>
            <label htmlFor="orth-assessment" className="radio-label">
                <input className="radio-input" id="orth-assessment" type="radio" value="assessment" name="type" defaultChecked/>
                <span className="custom-radio"><FaCheck className='font-awesome-check'/></span>
                Orthotic Assessment
            </label>

            <label htmlFor="orth-adjustment" className="radio-label">
                <input className="radio-input" id="orth-adjustment" type="radio" value="adjustment" name="type" />
                <span className="custom-radio"><FaCheck className='font-awesome-check'/></span>
                Orthotic Adjustment
            </label>
        </div>
        <BookingForm title={title} type={type} acknowledgements={acknowledgements}/>

        </div>
    </div>
    )

};

export default OrthoticAssessment;
